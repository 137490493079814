$main-color: navy;

*,
button {
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif;
}

html {
  background-color: $main-color;
  height: 100%;
  background: url('/bg.png') center no-repeat;
  background-size: cover;
}

body {
  height: calc(100% - 40px);
  padding: 0 10px;
  font-size: 12px;
}

#root {
  height: 100%;
}

.App {
  background-color: #fff;
  color: $main-color;
  text-align: center;
  margin: 20px auto;
  border-radius: 16px;
  height: 100%;
  max-width: 400px;
  padding: 20px;
  overflow: auto;
  box-shadow: 2px 2px 4px rgba($color: #000000, $alpha: 0.2);

  &.Login {
    height: calc(100% - 60px);
  }

  .title {
    font-size: 1.6em;
    margin: 0 0 14px;
  }

  .subtitle {
    font-size: 1.2em;
  }

  .label {
    font-size: 1em;
  }

  .logo {
    width: 60%;
  }
}

.button.is-primary {
  background: $main-color;
}

.button {
  font-size: 1.2em;
}

.checkin-item {
  border-top: 1px solid #ccc;
  padding: 10px 0;

  .columns {
    margin-bottom: 0;
  }

  .column {
    padding-bottom: 0;
  }
}


.privacy-policy {
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  // overflow: auto;

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.4em;
    margin-bottom: 10px;
  }
}

/* CustomConfirm.css */
.confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.confirm-box p {
  font-size: 1.6em;
  padding-bottom: 5px;
  text-align: center;
}

.confirm-box button {
  padding: 5px;
}

.confirm-box button {
  padding: 5px;
  border: 1px solid navy;
  background-color: white;
  color: navy;
  font-size: 1.6em;
}

.confirm-box button.confirm-button {
  background-color: navy;
  color: white;
}